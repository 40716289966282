<template>
  <div id="inside">
    <div class="container">
      <div class="row">
        <div class="col-12 text-body px-2 mb-2">
          <button class="btn justify-content-left text-decoration-none btn-link flex-row d-flex align-items-center pr-2 pb-2 pl-1" @click="voltar"><i class="fa fa-chevron-left fa-fw pull-left text-info"></i></button>
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-body pr-0">
          <h4 class="mb-1 text-primary d-flex flex-row align-items-center">Meu cadastro</h4>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row" style="">
        <div class="d-inline-flex flex-wrap flex-column col-acao mt-0 pt-0 col-lg-7">
          <div class="card">
            <div class="card-header" style="">
              <p class="m-0 text-info">Confira suas informações de cadastrais..</p>
            </div>
            <div class="card-body pb-2 pt-0">
              <div class="row">
                <div class="col-12 col-md-10" style="">
                  <p class="m-0 text-info">Nome:</p>
                  <h6 class="">{{ user.FullName }}</h6>
                  <p class="m-0 text-info">CPF:</p>
                  <h6>{{ formatDocument(user.DocNumber).split('.')[0].substring(0, 6) + '.XXX' + '.' + 'XXX-' + formatDocument(user.DocNumber).split('-')[1] }}</h6>
                  <p class="m-0 text-info">Data de nascimento:</p>
                  <h6>{{ user.BirthDate }}</h6>
                  <div class="row">
                    <div class="col-md-7" style="">
                      <p class="m-0 text-info">Endereço:</p>
                      <p style="">{{user.Locale != null ? user.Locale + ',' : ''}} {{user.Number != null ? user.Number + ',' : ''}} {{user.Complement != null ? user.Complement + ',' : ''}}</p>
                    </div>
                    <div class="col-md-5" style="">
                      <p class="m-0 text-info">CEP</p>
                      <p style="">{{ user.CEP ? formatCep(user.CEP) : ''}}</p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-7" style="">
                      <p class="m-0 text-info">Cidade:</p>
                      <p style="">{{user.City || ''}}&nbsp;</p>
                    </div>
                    <div class="col-md-5" style="">
                      <p class="m-0 text-info">UF</p>
                      <p style="">{{user.State || ''}}</p>
                    </div>
                  </div>
                  <hr>
                  <h6 class="mb-3">Editar cadastro:</h6>
                      <a class="btn mr-1 mb-1 btn-link" @click="alterarSenha"  style="text-decoration: none; cursor: pointer;" href="#">Alterar código</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions,mapState } from "vuex";
import { formatCurrencieValueBRL as formatValue, formatData ,formatDocument,formatCep} from "@/utils/helpers";
import { EventBus } from "@/utils/EventBus";
import { initializeGlobals } from '@/global';

export default {
    name: "Leitura",

    components: {
     
    },
    data () {
        return {
          links: [

            ],
            loadUserInfo: false,
            userInfo: null,
            largura: null,
            contracts: [],
            agreements: [],
            userDataStorage: {},
            pageLocation: null,
            url: null,
            protocol: null,
            path: null,
            host: null,
        }
    },

    async created() {
      this.updateCustomData();
      localStorage.setItem("currentPageName", 'cadastro');
      this.userDataStorage = JSON.parse(localStorage.getItem("userData"));
      this.protocol = window.location.protocol;
      this.host = window.location.host;
      this.path = window.location.pathname;
      this.pageLocation = this.protocol + '//' + this.host + this.path;
      this.largura = window.screen.width;
      
    },
    methods: {
        formatValue,
        formatDocument,
        formatData,
        formatCep,
        ...mapActions('cadastro',['ActionSetCurrentComponentRegister']),
        alterar() {
          this.buildAndSetUserData(this.userDataStorage.cpfCnpj, this.userDataStorage.id, this.protocol+'//'+ this.host+ 'dashboard/cadastro-atualizacao', 'Alterar');
          this.updateCustomData();
          this.emitUpdatePageViewEvent('dashboard/cadastro-atualizacao');
            this.ActionSetCurrentComponentRegister('cadastroAtualizacao');
        },
        alterarSenha() {
          this.eventClickAlterarCodigoGTM();
          this.buildAndSetUserData(this.userDataStorage.cpfCnpj, this.userDataStorage.id, this.protocol+'//'+ this.host+ '/dashboard/alterar-codigo', 'alterar-codigo');
          this.updateCustomData();
            this.emitUpdatePageViewEvent('dashboard/alterar-codigo');
            this.ActionSetCurrentComponentRegister('alterarSenha');
        },
        voltar() {
             this.buildAndSetUserData(this.userDataStorage.cpfCnpj, this.userDataStorage.id, this.protocol+'//'+ this.host+ '/dashboard', 'Dashboard');
             this.updateCustomData();
             this.$router.push({ name: 'dashboard' });
        },
        verBoletos() {
          this.buildAndSetUserData(this.userDataStorage.cpfCnpj, this.userDataStorage.id, this.protocol+'//'+ this.host+ 'dashboard/meus-boletos', 'Meus Boletos');
          this.updateCustomData();
          this.$router.push({
            name: "meusBoletos",
          });
        },

        cadastro() {
          return;
        },

        buildAndSetUserData(doc, id, pageLocation, pageName) {
          const userData = {
                          cpfCnpj: doc, // Substitua pelo CPF/CNPJ real
                          id: id, // Substitua pelo ID real
                          page_location: pageLocation,
                          pageName: pageName
                        };
                        localStorage.setItem("userData", JSON.stringify(userData));
        },

        emitUpdatePageViewEvent(pageName) {
            EventBus.$emit('updatePageView', pageName);
        },

        eventClickAlterarCodigoGTM() {
          this.pageName = localStorage.getItem('currentPageName') === null ? 'dashboard' : localStorage.getItem('currentPageName');
          const eventCategory = `portal-de-negociacao:porto:${this.pageName}`;
          const eventAction = 'click:link:alterar-codigo';

          const eventData = {
            event: 'select_content',
            ev_category: eventCategory,
            ev_action: eventAction,
            ev_label: '',
          };

          console.log("eventData clickAlterarCodigo Leitura: " + JSON.stringify(eventData))

          window.dataLayer.push({ event: 'select_content', ...eventData });

        },

        updateCustomData() {
          const globals = initializeGlobals();
          window.customData = globals.customData;
        }
    },
    computed:{ 
        ...mapState('auth', ['user','currentComponent']),
    }
};
</script>
<style scoped>

.btn-outline-primary:hover {
    background-color: #3f1e64 !important;
  }

</style>