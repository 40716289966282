<template>
  <div id="inside">
    <div class="container">
      <div class="row">
        <div class="col-12 text-body px-2 mb-2">
          <button class="btn justify-content-left text-decoration-none btn-link flex-row d-flex align-items-center pr-2 pb-2 pl-1" @click="voltarFormulario"><i class="fa fa-chevron-left fa-fw pull-left text-info"></i></button>
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-body pr-0">
          <h4 class="mb-1 text-primary d-flex flex-row align-items-center">Alterar código</h4>
        </div>
      </div>
    </div>
    <div class="container">
      <form  v-on:submit="changePassword" v-on:submit.prevent>
      <div class="row">
        <div class="d-inline-flex flex-wrap flex-column col-acao mt-0 pt-0 col-lg-5">
          <div class="card">
            <div class="card-header">
              <p class="m-0 text-info">Preencha os campos abaixo para alterar seu código. O novo código deverá conter *6 números.</p>
            </div>
            <div class="card-body pb-2 pt-0">
              <div class="row">
                        <div class="col-md-12">
                          <div class="form-group">
                            <input 
                              v-model="form.password"
                              :disabled="loading"
                              @input="$v.form.password.$touch()"
                              @blur="$v.form.password.$touch()"
                              @change="changeInfosCodeInput('codigo-atual')"
                              required
                              type="password" class="form-control my-3" placeholder="Código atual" name="Senha-atual"></div>

                            <div class="form-group">
                              <input 
                                v-model="form.newPassword"
                                :disabled="loading"
                                @input="$v.form.newPassword.$touch()"
                                @blur="$v.form.newPassword.$touch()"
                                @change="changeInfosCodeInput('novo-codigo')"
                                :class='{valid:passwordValidation.valid}'
                                required
                                maxlength="6"
                                v-model.lazy='form.newPassword'
                                type="password" class="form-control my-3" placeholder="Novo código" name="nova-senha"></div>

                              <div class="form-group">
                                <input 
                                  v-model="form.confirmPassword"
                                  :disabled="loading"
                                  @input="$v.form.confirmPassword.$touch()"
                                  @blur="$v.form.confirmPassword.$touch()"
                                  @change="changeInfosCodeInput('repetir-novo-codigo')"
                                  maxlength="6"
                                  required
                                  type="password" class="form-control my-3" placeholder="Repetir novo código" name="repetir-nova-senha"></div>
                        </div>
                      </div>
                      <div v-if="docNumberErrors.length" style="text-align:left;font-size:11px;">
                        <b v-for="error in docNumberErrors" v-bind:key="error" v-bind:class="classMessage" class="form-text">{{ error }}</b>
                      </div>
                      <div class="row" v-if="loading">
                            <div class="justify-center" style="margin-top:5px;margin-left:0px;width:100% !important;">
                                <ProgressLoading v-bind:loading="loading"/>
                            </div>
                        </div>
                        <button v-if="!loading" type="submit" style="margin-top:10px" class="btn mr-1 mb-1 btn-outline-primary btn-orange" >Confirmar</button>
            </div>
          </div>
        </div>
      </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions,mapState } from "vuex";
import { validationMixin } from 'vuelidate';
import { required, minLength } from 'vuelidate/lib/validators';
import ProgressLoading from '../../../../modules/auth/components/ProgressLoading.vue';
import { EventBus } from "@/utils/EventBus";
import { initializeGlobals } from '@/global';

export default {
    name: "alterar-codigo",
    mixins: [validationMixin],
    validations: {
        form: {
            password: {required, minLength: minLength(6)},
            newPassword: {required, minLength: minLength(6)},
            confirmPassword: {required, minLength: minLength(6)}
        }
    },
    data: () => ({
        links:[],
        form : {
            password: null,
            newPassword: null,
            confirmPassword:null
        },
        error: {
            alert: false,
            status: null,
            message: null
        },
        rules: [
          { message:'Pelo menos uma letra minúscula', regex:/[a-z]+/ },
          { message:"Pelo menos uma letra maiúscula.",  regex:/[A-Z]+/ },
          { message:"Mínimo de 8 caractes.", regex:/.{8,}/ },
          { message:"Pelo menos um número.", regex:/[0-9]+/ }
        ],
        errors: false,
        loading: false,
        largura: null,
        contracts: [],
        agreements: [],
        userDataStorage: {},
        pageLocation: null,
        url: null,
        protocol: null,
        path: null,
        host: null,
        userAdm: {},
        ipUser: null
    }),
    mounted() {
      this.$emit('updatePageView', this.pageLocation);
    },
    async created(){
      this.updateCustomData();
      this.ipUser = localStorage.getItem('ipUser');
      localStorage.setItem("currentPageName", 'alterar-codigo');
      this.userDataStorage = JSON.parse(localStorage.getItem("userData"));
      this.userAdm = JSON.parse(localStorage.getItem("userAdm"));
      this.protocol = window.location.protocol;
      this.host = window.location.host;
      this.path = window.location.pathname;
      this.pageLocation = this.protocol + '//' + this.host + this.path;
      this.largura = window.screen.width;
    },
    components: {
      ProgressLoading
    },
    methods: {
        ...mapActions('cadastro',['ActionSetCurrentComponentRegister','ActionUpdateDebtorPassword']),
        voltarFormulario() {
          this.buildAndSetUserData(this.userDataStorage.cpfCnpj, this.userDataStorage.id, this.protocol+'//'+ this.host+ '/dashboard/cadastro', 'cadastro');
          this.updateCustomData();
          this.emitUpdatePageViewEvent('dashboard/cadastro');
            this.ActionSetCurrentComponentRegister('cadastroLeitura');
        },
        voltar() {
          this.buildAndSetUserData(this.userDataStorage.cpfCnpj, this.userDataStorage.id, this.protocol+'//'+ this.host+ '/dashboard/dashboard', 'Dashboard');
          this.updateCustomData();
          this.$router.push({ name: 'dashboard' });
        },
        limparFormulario: function () {
            this.form.password = '';
            this.form.newPassword = '';
            this.form.confirmPassword = '';
        },
        async changePassword(){
          this.eventClickToConfirmGTM();
           this.loading = true;
           this.$v.$touch();
           this.docNumberErrors.pop();
          
            // if(!this.passwordValidation.valid){
            //   this.loading = false;
            //   this.docNumberErrors.push('nova senha não atende aos requisitos mínimos');
            //   return;
            // }

            if (this.form.confirmPassword !=  this.form.newPassword) {
              this.loading = false;
              const pageName = this.$options.name;
              const eventCategory = `portal-de-negociacao:porto:${pageName}`;
              const eventAction = 'alterar-codigo:alert';
              const eventLabel = 'novo código e confirmação não conferem';
              const eventCode = 401;
              const eventErrorService = '';
              const eventAlertService = 'novo código e confirmação não conferem';
              const eventAlert = 'self-service';
              const eventAlertType = 'erro';
              const eventData = {
                event: 'alert',
                ev_category: eventCategory,
                ev_action: eventAction,
                ev_label: eventLabel,
                alert_code: eventCode,
                error_service: eventErrorService,
                alert_service_message: eventAlertService,
                alert_event: eventAlert,
                alert_type: eventAlertType,
              };
                    
              window.dataLayer.push({ event: 'alert', ...eventData });
              
              this.flashMessage.show({
                timeout: 300,
                status: "error",
                position: "top right",
                title: "Erro",
                message: "novo código e confirmação não conferem",
              });
              return;
           }

           if (this.docNumberErrors.length > 0) {
              this.loading = false;
              return;
           }

            try {
                let response = await this.ActionUpdateDebtorPassword({
                  PromoCode:this.form.newPassword,
                  PromoCodeOld:this.form.password,
                  UserID: this.userAdm ? this.userAdm.UserID : null,
                  UserFullName: this.userAdm ? this.userAdm.UserFullName : null,
                  OS:navigator.platform,
                  IP: this.ipUser ? this.ipUser : '',
                });
                 this.loading = false;

                if(response.status == 401){
                  this.docNumberErrors.push('As credênciais fornecidas estão incorretas, verifique seu código e tente novamente.');
                  this.error.status = 'ERROR';
                  this.eventCallbackConfirmFailureGTM(response);
                  return;
                }

                 this.limparFormulario();
                 this.flashMessage.show({
                  timeout: 1000,
                  status: "success",
                  title: "Sucesso",
                  blockClass: "custom-block-class",
                  message: "Seu código de acesso foi alterado com sucesso!",
                });
                this.eventCallbackConfirmSuccessGTM(response);
            } catch (error) {
               this.loading = false;
               this.docNumberErrors.push('Ocorreu um erro ao tentar atualizar seu código de acesso, tente novamente mais tarde.');
               this.error.status = 'ERROR';
               this.eventCallbackConfirmFailureGTM(error);
            }
        },

        cadastroCodigo() {
          this.buildAndSetUserData(this.userDataStorage.cpfCnpj, this.userDataStorage.id, this.protocol+'//'+ this.host+ '/dashboard/alterar-codigo', 'alterar-codigo');
          this.updateCustomData();
          this.emitUpdatePageViewEvent('dashboard/alterar-codigo');
          this.ActionSetCurrentComponentRegister('alterarSenha');
        },

        cadastro() {
          this.buildAndSetUserData(this.userDataStorage.cpfCnpj, this.userDataStorage.id, this.protocol+'//'+ this.host+ '/dashboard/cadastro', 'cadastro');
          this.updateCustomData();
          this.emitUpdatePageViewEvent('dashboard/cadastro');
          this.ActionSetCurrentComponentRegister('cadastroLeitura');
        },

        buildAndSetUserData(doc, id, pageLocation, pageName) {
          const userData = {
                          cpfCnpj: doc, // Substitua pelo CPF/CNPJ real
                          id: id, // Substitua pelo ID real
                          page_location: pageLocation,
                          pageName: pageName
                        };
                        localStorage.setItem("userData", JSON.stringify(userData));
        },

        emitUpdatePageViewEvent(pageName) {
            EventBus.$emit('updatePageView', pageName);
        },

        changeInfosCodeInput(input) {
          const pageName = this.$options.name;
          const eventCategory = `portal-de-negociacao:porto:${pageName}`;
          const eventAction = `preencher:input:${input}`;
          const eventData = {
            event: 'select_content',
            ev_category: eventCategory,
            ev_action: eventAction,
            ev_label: '',
          };

          console.log("eventData changeInfosCodeInput AlterarSenha: " + JSON.stringify(eventData))

          window.dataLayer.push({ event: 'select_content', ...eventData });
            
        },

        eventClickToConfirmGTM() {

          const pageName = this.$options.name;
          const eventCategory = `portal-de-negociacao:porto:${pageName}`;
          const eventAction = 'click:button:confirmar';

          const eventData = {
            event: 'select_content',
            ev_category: eventCategory,
            ev_action: eventAction,
            ev_label: '',
          };

          console.log('eventData click Confirmar: ' + JSON.stringify(eventData));

          window.dataLayer.push({ event: 'select_content', ...eventData });

        },

        eventCallbackConfirmSuccessGTM(callback) {
          console.log(callback)
          const pageName = this.$options.name;
          const eventCategory = `portal-de-negociacao:porto:${pageName}`;
          const eventAction ='alterar-codigo:sucesso';

          const eventData = {
            event: 'self_service',
            ev_category: eventCategory,
            ev_action: eventAction,
            ev_label: callback.data.message,
            service_protocol: '',
            service_detail: 'Alteração de código de acesso',
            service_type: 'solicitacao',
          };

          console.log("eventData Código SUCESSO: " + JSON.stringify(eventData))

          window.dataLayer.push({ event: 'self_service', ...eventData });
        },


        eventCallbackConfirmFailureGTM(error) {
                    const pageName = this.$options.name;
                    const eventCategory = `portal-de-negociacao:porto:${pageName}`;
                    const eventAction = 'alterar-codigo:alert';
                    const eventLabel = error.data.message;
                    const eventCode = error.status;
                    const eventErrorService = '';
                    const eventAlertService = error.data.message;
                    const eventAlert = 'self-service';
                    const eventAlertType = 'erro';
                    const eventData = {
                      event: 'alert',
                      ev_category: eventCategory,
                      ev_action: eventAction,
                      ev_label: eventLabel,
                      alert_code: eventCode,
                      error_service: eventErrorService,
                      alert_service_message: eventAlertService,
                      alert_event: eventAlert,
                      alert_type: eventAlertType,
                    };

                    console.log("eventData Alterar Código ERRO: " + JSON.stringify(eventData))

                    window.dataLayer.push({ event: 'alert', ...eventData });
    },

    updateCustomData() {
      const globals = initializeGlobals();
      window.customData = globals.customData;
    },
    },
    computed:{ 
        ...mapState('auth', ['user','currentComponent']),
        docNumberErrors () {
            const errors = [];
            if(!this.$v.form.password.$dirty) return errors;

            if(!this.$v.form.newPassword.$dirty) return errors;

            if(!this.$v.form.confirmPassword.$dirty) return errors;
            this.form.confirmPassword !=  this.form.newPassword && errors.push('novo código e confirmação não conferem');

            return errors;
        },
        classMessage: function () {
            return {
                'text-danger': this.error.status == "ERROR",  
                'text-info': this.error.status == "SUCCESS", 
                'text-muted': !this.error.status == "ERROR"
            }
        },
       
        passwordValidation () {
          let errors = []
          for (let condition of this.rules) {
            if (!condition.regex.test(this.form.newPassword)) {
              errors.push(condition.message)
            }
          }
          if (errors.length === 0) {
            return { valid:true, errors }
          } else {
            return { valid:false, errors }
          }
        },

        

    }
};
</script>