<template>
   <component v-bind:is="currentComponent"></component>
</template>

<script>
import { mapActions,mapState } from "vuex";
import { formatCurrencieValueBRL as formatValue, formatData ,formatDocument,formatCep} from "@/utils/helpers";
import ContentMenu from '../components/ContentMenu.vue';
import ProgressLoading from '../../../modules/auth/components/ProgressLoading.vue';
import Leitura from './components/Leitura.vue'
import Alteracao from './components/Alteracao.vue'
import AlteracaoSenha from './components/AlterarSenha.vue'
import { EventBus } from "@/utils/EventBus";
import { initializeGlobals } from '@/global';

export default {
    name: "Cadastro",

    components: {
      ContentMenu,
      ProgressLoading,
      "cadastroLeitura": Leitura,
      "cadastroAtualizacao": Alteracao,
      "alterarSenha": AlteracaoSenha
    },
    data () {
        return {
            loadUserInfo: false,
            userInfo: null,
            userDataStorage: {},
            pageLocation: null,
            url: null,
            protocol: null,
            path: null,
            host: null,
        }
    },
    async created() {
        this.updateCustomData();
        this.userDataStorage = JSON.parse(localStorage.getItem("userData"));
        this.protocol = window.location.protocol;
        this.host = window.location.host;
        this.path = window.location.pathname;
        this.pageLocation = this.protocol + '//' + this.host + this.path;

        this.ActionSetCurrentComponentRegister('cadastroLeitura');

        if(this.$route.params.password) {
            this.buildAndSetUserData(this.userDataStorage.cpfCnpj, this.userDataStorage.id, this.protocol+'//'+ this.host+ '/dashboard/alterar-codigo', 'alterar-codigo');
            this.updateCustomData();
            this.emitUpdatePageViewEvent('alterar-codigo');
            this.ActionSetCurrentComponentRegister('alterarSenha');

        }

        if(this.$route.params.updateInfo) {
            this.buildAndSetUserData(this.userDataStorage.cpfCnpj, this.userDataStorage.id, this.protocol+'//'+ this.host+ '/dashboard/cadastro-atualizacao', 'Alterar');
            this.updateCustomData();
            this.emitUpdatePageViewEvent('cadastro-atualizacao');
            this.ActionSetCurrentComponentRegister('cadastroAtualizacao');
        }

    },
    methods: {
        formatValue,
        formatDocument,
        formatData,
        formatCep,
        ...mapActions('cadastro', ['ActionSetCurrentComponentRegister']),
        voltar() {
            this.buildAndSetUserData(this.userDataStorage.cpfCnpj, this.userDataStorage.id, this.protocol+'//'+ this.host+ '/dashboard/cadastro', 'cadastro');
            this.updateCustomData();
             this.$router.push({ name: 'dashboard' });
        },

        buildAndSetUserData(doc, id, pageLocation, pageName) {
          const userData = {
                          cpfCnpj: doc, // Substitua pelo CPF/CNPJ real
                          id: id, // Substitua pelo ID real
                          page_location: pageLocation,
                          pageName: pageName
                        };
                        console.log('buildAndSetUserData alterar cadastro: ' + JSON.stringify(userData));
                        localStorage.setItem("userData", JSON.stringify(userData));
        },

        emitUpdatePageViewEvent(pageName) {
            EventBus.$emit('updatePageView', pageName);
        },

        updateCustomData() {
          const globals = initializeGlobals();
          window.customData = globals.customData;
        }
    },
    computed:{ 
        ...mapState('cadastro', ['currentComponent']),
    }
};
</script>