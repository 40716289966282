<template>
  <div id="inside">
    <div class="container">
      <div class="row">
        <div class="col-12 text-body px-2 mb-2">
          <button class="btn justify-content-left text-decoration-none btn-link flex-row d-flex align-items-center pr-2 pb-2 pl-1" @click="voltarFormulario"><i class="fa fa-chevron-left fa-fw pull-left text-info"></i></button>
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-body pr-0">
          <h4 class="mb-1 text-primary d-flex flex-row align-items-center">Meu cadastro</h4>
        </div>
      </div>
    </div>

    <div class="container">
      <form v-on:submit="saveUserInfo" v-on:submit.prevent>
      <div class="row" style="">
        <!-- aqui-->
        <div class="d-inline-flex flex-wrap flex-column col-acao mt-0 pt-0 col-lg-7">
          <div class="card">
            <div class="card-header" style="">
              <p class="m-0 text-info">Preencha os campos abaixo para alterar seu cadastro.</p>
            </div>
            <div class="card-body pb-2 pt-0">
              <div class="row">
                <div class="col-12 col-md-12" style="">
                  <p class="m-0 text-info">Nome:</p>
                  <h6 class="">{{ user.FullName }}</h6>
                  <p class="m-0 text-info">CPF:</p>
                  <h6> {{ formatDocument(user.DocNumber).split('.')[0].substring(0, 6) + '.XXX' + '.' + 'XXX-' + formatDocument(user.DocNumber).split('-')[1] }}</h6>
                  <p class="m-0 text-info" v-if="user.BirthDate">Data de nascimento:</p>
                  <h6>{{ user.BirthDate }}</h6>
                  <div class="row">
                    <div class="col-md-7" style="">
                      <div class="form-group"><input 
                              v-model="form.endereco"
                              maxlength="100"
                              :disabled="loading"
                              :error-messages="docNumberErrors"
                              required
                              @input="$v.form.endereco.$touch()"
                              @blur="$v.form.endereco.$touch()"
                              type="text" class="form-control my-3" placeholder="Endereço"></div>
                    </div>
                    <div class="col-md-5" style="">
                      <div class="form-group"><input 
                              v-model="form.cep"
                              :disabled="loading"
                              v-mask="'#####-###'"
                              required
                              type="text" class="form-control my-3" placeholder="CEP"></div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-7" style="">
                      <div class="form-group"><input 
                                v-model="form.city"
                                :disabled="loading"
                                required
                                maxlength="20"
                                type="text" class="form-control my-3" placeholder="Cidade"></div>
                    </div>
                    <div class="col-md-5" style="">
                      <div class="form-group"><input 
                              v-model="form.state"
                              :disabled="loading"
                              required
                              maxlength="2"
                              type="text" class="form-control my-3" placeholder="UF"></div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-7" style="">
                      <div class="form-group"><input 
                                v-model="form.email"
                                :disabled="loading"
                                :required="!form.phone && !form.mobileNumber ? true : false"
                                maxlength="100"
                                type="text" class="form-control my-3" placeholder="E-mail"></div>
                    </div>
                    <div class="col-md-5" style="">
                      <div class="form-group"><input 
                                v-model="form.mobileNumber"
                                :disabled="loading"
                                :required="!form.email && !form.phone ? true : false"
                                v-mask="'(##)# ####-####'"
                                type="text" class="form-control my-3" placeholder="Celular"></div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-5" style="">
                      <div class="form-group"><input 
                                v-model="form.phone"
                                :disabled="loading"
                                :required="!form.email && !form.mobileNumber ? true : false"
                                v-mask="['(##)####-#### ','(##)# ####-####']"
                                type="text" class="form-control my-3" placeholder="Telefone"></div>
                    </div>
                  </div>
                  <hr>
                    <button v-if="!loading" type="submit" class="btn mr-1 mb-1 btn-outline-primary">Confirmar</button>
                </div>
                <div class="justify-center" style="margin-top:5px;margin-left:0px;width:100% !important;">
                    <ProgressLoading v-bind:loading="loading"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </form>
    </div>
  </div>
</template>

<script>

import { mapActions,mapState } from 'vuex';
import { mask } from 'vue-the-mask';
import { validationMixin } from 'vuelidate';
import { required, minLength } from 'vuelidate/lib/validators';
import ProgressLoading from '../../../../modules/auth/components/ProgressLoading.vue';
import { formatCurrencieValueBRL as formatValue, formatData,formatDocument,formatRemovePhoneMask,formatCep } from "@/utils/helpers";
import { initializeGlobals } from '@/global';

export default {
    name: "Alterar",
    directives: {mask},
    mixins: [validationMixin],
    validations: {
        form: {
            endereco: {required, minLength: minLength(4)}
        }
    },
    components: {
      ProgressLoading
    },
    data: () => ({
       links:[],
        form : {
            endereco: null,
            number: null,
            cep:null,
            complement:null,
            region:null,
            state:null,
            city:null,
            email:null,
            phone:null,
            mobileNumber:null
        },
        error: {
            alert: false,
            status: null,
            message: null
        },
        errors: false,
        loading: false,
        largura: null,
        contracts: [],
        agreements: [],
        ipUser: null
    }),
    async created() {
      this.updateCustomData();
      this.ipUser = localStorage.getItem('ipUser');
      this.contracts = this.$route.params.contracts;
      this.agreements = this.$route.params.agreements;
      this.largura = window.screen.width;
      this.user.Menus.forEach(menuId => {
            switch(parseInt(menuId)){
                case 2:
                    this.links.push({ icon: '', text: 'Contratos Negociáveis', route: '/dashboard/minhas-dividas' });
                    break;
                case 1:
                    this.links.push({ icon: '', text: 'Boletos Ativos', route: 'meusBoletos' });
                    break;
                case 3:
                    this.links.push({ icon: '', text: 'Acordos', route: '/dashboard/meus-acordos' });
                    break;
            }
        });

        this.form.endereco = this.user.Locale;
        this.form.cep = this.user.CEP;
        this.form.number = this.user.Number;
        this.form.complement = this.user.Complement;
        this.form.state = this.user.State;
        this.form.city = this.user.City;
        this.form.email = this.user.Email;
        this.form.phone = this.user.PhoneNumber;
        this.form.mobileNumber = this.user.MobileNumber;
        this.form.region = this.user.Region;
    },
    methods: {
        formatValue,
        formatDocument,
        formatRemovePhoneMask,
        formatData,
        formatCep,
        ...mapActions('cadastro',['ActionUpdateDebtor','ActionSetCurrentComponentRegister']),
        voltarFormulario() {
            this.ActionSetCurrentComponentRegister('cadastroLeitura');
        },
       
        async saveUserInfo(e){
           e.preventDefault();
           this.loading = true;
           this.$v.$touch();
           this.docNumberErrors.pop();
           
           if (this.docNumberErrors.length > 0) {
              this.loading = false;
              return;
           }
           try {
                let response = await this.ActionUpdateDebtor({
                  Locale: this.form.endereco,
                  Number: this.form.number,
                  Complement: this.form.complement,
                  Region: this.form.region,
                  City:this.form.city,
                  State: this.form.state,
                  CEP: this.formatRemovePhoneMask(this.form.cep),
                  PhoneNumber: this.form.phone ? this.formatRemovePhoneMask(this.form.phone) : null,
                  MobileNumber: this.form.mobileNumber ? this.formatRemovePhoneMask(this.form.mobileNumber) : null,
                  Email: this.form.email === '' ? null : this.form.email,
                  IP: this.ipUser ? this.ipUser : '',
                });
                 this.loading = false;
                if(response.status != 200){
                  this.docNumberErrors.push('Ocorreu um erro ao tentar atualizar seus dados, tente novamente mais tarde.');
                  this.error.status = 'ERROR';
                  return;
                }
                this.flashMessage.show({
                  timeout: 1000,
                  status: "success",
                  title: "Sucesso",
                  blockClass: "custom-block-class",
                  message: "Seus dados foram alterados com sucesso!",
                });
            } catch (error) {
               this.loading = false;
               this.flashMessage.show({
                        timeout:300,
                        status: 'error',
                        position:'top right', 
                        title: 'Erro', 
                        message: 'Ocorreu um erro ao tentar atualizar seus dados, tente novamente mais tarde.'});
            }
         
        },

        verBoletos() {
          this.$router.push({
            name: "meusBoletos",
          });
        },

        verAcordos() {
          this.$router.push({
            name: "meusAcordos",
          });
        },

        meusContratos() {
          this.$router.push({
            name: "meusContratos",
          });
        },

        cadastro() {
          return;
        },

        alterar() {
            this.ActionSetCurrentComponentRegister('cadastroAtualizacao');
        },
        alterarSenha() {
            this.ActionSetCurrentComponentRegister('alterarSenha');
        },

        updateCustomData() {
          const globals = initializeGlobals();
          window.customData = globals.customData;
        }
        
    },
    computed:{ 
        ...mapState('auth', ['user']),
        docNumberErrors () {
            const errors = [];
            if(!this.$v.form.endereco.$dirty) return errors;
            !this.$v.form.endereco.minLength && errors.push('digite um endereço válido');
            !this.$v.form.endereco.required && errors.push('endereço é obrigatório!');

            return errors;
        },
        classMessage: function () {
            return {
                'text-danger': this.error.status == "ERROR",  
                'text-info': this.error.status == "SUCCESS", 
                'text-muted': !this.error.status == "ERROR"
            }
        },
    }
};
</script>